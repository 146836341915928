<template>
    <div>
        <!-- Header -->
        <!-- <span v-show="firstMode == 'userList'" class="header-page">
            <span class="title-header text-h4 pt-3 pl-5">
            User List
            </span>
        </span>

        <span v-show="firstMode == 'userEdit'" class="header-page">
            <span class="title-header text-h4 pt-3 pl-5">
            Edit User
            </span>
        </span>

        <span v-show="firstMode == 'userAdd'" class="header-page">
            <span class="title-header text-h4 pt-3 pl-5">
            Add User
            </span>
        </span> -->

        <!-- View User List -->
        <div v-show="firstMode == 'userList'">

            <div class="pa-5">

                <!-- Button -->
                <div class="px-5">
                    <v-row>
                        <div>
                            <v-card class="pa-5">
                                <small>
                                    <v-icon dense class="primary--text mb-1">mdi-information</v-icon>
                                    Click here to go to Civil & Structure Group Function : 
                                    <span
                                    @click="csFunction()"
                                    underlined
                                    class="cust-textFunction"
                                    >(here)</span>
                                </small>
                                <br>
                                <small>
                                    <v-icon dense class="primary--text mb-1">mdi-information</v-icon>
                                    Click here to go to Mechanical & Electrical Group Function : 
                                    <span
                                    @click="wmeFunction()"
                                    underlined
                                    class="cust-textFunction"
                                    >(here)</span>
                                </small>
                            </v-card>
                            
                        </div>
                    </v-row>
                    
                    <v-row class="pt-3 pb-3">
                        <v-divider></v-divider>
                    </v-row>

                    <v-row>
                        <v-spacer></v-spacer>

                        <v-btn class="mr-5" color="primary" @click="addUser()">
                            ADD USER
                        </v-btn>
                    </v-row>

                </div>

                <!-- Search Table -->
                <div class="pt-5">
                    <v-row>
                        <v-col cols="9" class="pb-0"></v-col>
                        <v-col cols="3" class="pr-2 pb-0">
                            <v-text-field
                            dense
                            outlined
                            class="mr-3"
                            placeholder="Search"
                            v-model="searchTable"
                            prepend-inner-icon="mdi-magnify"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>

                <!-- Data Table -->
                <div class="pa-2">
                    <v-data-table
                        :headers="thead"
                        :items="tbody"
                        :items-per-page="10"
                        :search="searchTable"
                        class="elevation-1 thead-class"
                        :loading="loadingTable"
                    >

                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                v-bind="attrs"
                                v-on="on"
                                color="primary" @click="clickLink(item)">
                                    mdi-text-box 
                                </v-icon>
                            </template>
                            <span>Details</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:[`item.cs`]="{ item }">
                        <span v-if="item.cs == true">
                            <v-icon color="success">mdi-check</v-icon>
                        </span>
                        <span v-else-if="item.cs == false">
                            <v-icon color="red">mdi-close</v-icon>
                        </span>
                    </template>

                    <template v-slot:[`item.rf`]="{ item }">
                        <span v-if="item.rf == true">
                            <v-icon color="success">mdi-check</v-icon>
                        </span>
                        <span v-else-if="item.rf == false">
                            <v-icon color="red">mdi-close</v-icon>
                        </span>
                    </template>

                    <template v-slot:[`item.wme`]="{ item }">
                        <span v-if="item.wme == true">
                            <v-icon color="success">mdi-check</v-icon>
                        </span>
                        <span v-else-if="item.wme == false">
                            <v-icon color="red">mdi-close</v-icon>
                        </span>
                    </template>

                    <!-- <template v-slot:header="{ props }">
                        
                    </template> -->

                    </v-data-table>
                </div>
                
            </div>
        </div> 

        <!-- Dialog Edit User -->
        <v-dialog
        v-model="dialogEditUser"
        persistent
        width="90vh"
        scrollable
        >
            <v-card>
                <v-toolbar
                color="#04994B"
                dark
                >
                Edit User

                <v-spacer></v-spacer>
                    <v-btn
                    icon
                    @click="closeEditUser"
                    >
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <!-- User Details -->
                    <v-row class="pt-5">
                        <v-col>
                            <v-card
                            class="mx-auto"
                            outlined
                            >
                                <v-list-item-content>
                                    <span class="font-weight-bold mb-1 ml-2">
                                        User Details 
                                    </span>

                                    <v-row>
                                        <v-col class="pt-0 pb-5">
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp? '4' : '12'">
                                            <span>User Id</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp? '8' : '12'">
                                            <v-text-field
                                            v-model="selectItemUser.userId"
                                            outlined
                                            hide-details
                                            dense
                                            readonly
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp? '4' : '12'">
                                            <span>Username</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp? '8' : '12'">
                                            <v-text-field
                                            v-model="selectItemUser.username"
                                            outlined
                                            hide-details
                                            dense
                                            readonly
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp? '4' : '12'">
                                            <span>Email</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp? '8' : '12'">
                                            <v-text-field
                                            v-model="selectItemUser.email"
                                            outlined
                                            hide-details
                                            dense
                                            readonly
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                </v-list-item-content>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-divider class="my-2"></v-divider>

                    <!-- Indicator CS, WME, RF Button -->

                    <div align="left">
                        <v-row>
                            <v-col>
                                <v-btn
                                class="mr-3"
                                :outlined="(secondMode != 'CS')"
                                color="success"
                                @click="openCard('CS')"
                                >
                                    CS
                                </v-btn>

                                <v-btn
                                class="mr-3"
                                :outlined="(secondMode != 'WME')"
                                color="success"
                                @click="openCard('WME')"
                                >
                                    WME
                                </v-btn>

                                <v-btn
                                class="mr-3"
                                color="success"
                                :outlined="(secondMode != 'RF')"
                                @click="openCard('RF')"
                                >
                                    RF
                                </v-btn>

                                <v-btn
                                color="success"
                                :outlined="(secondMode != 'TEMAN2')"
                                @click="openCard('TEMAN2')"
                                >
                                    TEMAN 2
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                    <!-- CS User Details -->
                    <v-row v-show="secondMode == 'CS'">
                        <v-col>
                            <v-card
                            class="mx-auto"
                            outlined
                            >
                                <v-list-item-content>
                                    <span class="font-weight-bold mb-1 ml-2">
                                        Civil & Structure
                                    </span>

                                    <v-row>
                                        <v-col class="pt-0 pb-5">
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Status User</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            v-model="selectItemUser.csActivated" 
                                            :items="listActivated"
                                            item-text="label"
                                            label="Status User"
                                            outlined 
                                            hide-details
                                            @change="changeRFActivated($event)"
                                            dense
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Location</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            ref="autoComplete"
                                            v-model="selectItemUser.csLocation"
                                            :items="listCSLocation"
                                            label="Location"
                                            outlined
                                            hide-details
                                            dense
                                            :disabled="selectItemUser.csActivated == 0"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Section / Department</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-text-field
                                            v-model="selectItemUser.csSection"
                                            outlined
                                            label="Section / Department"
                                            hide-details
                                            dense
                                            :disabled="selectItemUser.csActivated == 0"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Group Id</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            v-model="selectItemUser.csGroupId"
                                            :items="listCSGroupId"
                                            label="Group Id"
                                            outlined
                                            hide-details
                                            dense
                                            @change="changeCSGroupId($event)"
                                            :disabled="selectItemUser.csActivated == 0"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row v-show="selectItemUser.csGroupId != null && selectItemUser.csGroupId != ''" class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-card
                                            class="pa-3 py-3"
                                            elevation="0"
                                            outlined
                                            >
                                            <v-row>
                                                <v-col 
                                                cols="4"
                                                v-for="(item,i) in listCSDropdownCheckbox"
                                                :key="i"
                                                >
                                                    <v-checkbox
                                                    dense
                                                    v-model="item.model"
                                                    :label="item.key"
                                                    :disabled="selectItemUser.csActivated == 0"
                                                    ></v-checkbox>
                                                    <!-- {{ item }} -->
                                                </v-col>
                                            </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Access Level</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            v-model="selectItemUser.csAccessLevel"
                                            :items="listCSAccessLevel"
                                            label="Access Level"
                                            outlined
                                            hide-details
                                            dense
                                            :disabled="selectItemUser.csActivated == 0"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                </v-list-item-content>
                            </v-card>
                        </v-col>
                    </v-row>

                    <!-- WME User Details -->
                    <v-row v-show="secondMode == 'WME'">
                        <v-col>
                            <v-card
                            class="mx-auto"
                            outlined
                            scrollable
                            >
                                <v-list-item-content>
                                    <span class="font-weight-bold mb-1 ml-2">
                                        Mechanical & Electrical
                                    </span>

                                    <v-row>
                                        <v-col class="pt-0 pb-5">
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Status User</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            v-model="selectItemUser.wmeActivated" 
                                            :items="listActivated"
                                            item-text="label"
                                            label="Status User"
                                            outlined 
                                            hide-details
                                            @change="changeRFActivated($event)"
                                            dense></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Location</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            v-model="selectItemUser.wmeLocation"
                                            :items="listWMELocation"
                                            label="Location"
                                            outlined
                                            hide-details
                                            dense
                                            @change="changeRFActivated($event)"
                                            :disabled="selectItemUser.wmeActivated == 0"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Section / Department</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-text-field
                                            v-model="selectItemUser.wmeSection"
                                            label="Section / Department"
                                            outlined
                                            hide-details
                                            dense
                                            :disabled="selectItemUser.wmeActivated == 0"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Group Id</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            v-model="selectItemUser.wmeGroupId"
                                            :items="listWMEGroupId"
                                            label="Group Id"
                                            outlined
                                            hide-details
                                            dense
                                            @change="changeWMEGroupId($event)"
                                            :disabled="selectItemUser.wmeActivated == 0"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row v-show="selectItemUser.wmeGroupId != null && selectItemUser.wmeGroupId != ''" class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-card
                                            class="pa-3 py-3"
                                            elevation="0"
                                            outlined
                                            >
                                            <v-row>
                                                <v-col 
                                                cols="4"
                                                v-for="(item,i) in listWMEDropdownCheckbox"
                                                :key="i"
                                                >
                                                    <v-checkbox
                                                    v-model="item.model"
                                                    :label="item.key"
                                                    :disabled="selectItemUser.wmeActivated == 0"
                                                    ></v-checkbox>
                                                    <!-- {{ item }} -->
                                                </v-col>
                                            </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Access Level</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            v-model="selectItemUser.wmeAccessLevel"
                                            :items="listWMEAccessLevel"
                                            label="Access Level"
                                            outlined
                                            hide-details
                                            dense
                                            :disabled="selectItemUser.wmeActivated == 0"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                </v-list-item-content>
                            </v-card>
                        </v-col>
                    </v-row>

                    <!-- RF User Details -->
                    <v-row v-show="secondMode == 'RF'">
                        <v-col>
                            <v-card
                            class="mx-auto"
                            outlined
                            >
                                <v-list-item-content>
                                    <span class="font-weight-bold mb-1 ml-2">
                                        Road Furniture
                                    </span>

                                    <v-row>
                                        <v-col class="pt-0 pb-5">
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Status User</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            v-model="selectItemUser.rfActivated"
                                            label="Status User" 
                                            :items="listActivated"
                                            item-text="label"
                                            outlined
                                            hide-details
                                            dense
                                            @change="changeRFActivated($event)"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Group Id</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            v-model="selectItemUser.rfGroupId" 
                                            :items="listRFGroupId"
                                            label="Group Id" 
                                            outlined 
                                            hide-details 
                                            dense
                                            @change="changeRFGroupId($event)"
                                            :disabled="selectItemUser.rfActivated == 0"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row v-show="selectItemUser.rfGroupId != null && selectItemUser.rfGroupId != ''" class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-card
                                            class="pa-3 py-3"
                                            elevation="0"
                                            outlined
                                            >
                                            <v-row>
                                                <v-col 
                                                cols="4"
                                                v-for="(item, i) in listRFCheckbox"
                                                :key="i"
                                                >
                                                    <v-checkbox
                                                    v-model="item.model"
                                                    :label="item.label"
                                                    :disabled="selectItemUser.rfActivated == 0"
                                                    ></v-checkbox>
                                                    <!-- {{ item }} -->
                                                </v-col>
                                            </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    

                                </v-list-item-content>
                            </v-card>
                        </v-col>
                    </v-row>

                    <!-- TEMAN 2 User Details -->
                     <v-row v-show="secondMode == 'TEMAN2'">
                        <v-col>
                            <v-card
                            class="mx-auto"
                            outlined
                            >
                                <v-list-item-content>
                                    <span class="font-weight-bold mb-1 ml-2">
                                        Teman 2
                                    </span>

                                    <v-row>
                                        <v-col class="pt-0 pb-5">
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Status User</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            v-model="selectItemUser.teman2Activated"
                                            label="Status User" 
                                            :items="listActivated"
                                            item-text="label"
                                            outlined
                                            hide-details
                                            dense
                                            @change="changeTEMAN2Activated($event)"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Group Id</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            v-model="selectItemUser.teman2GroupId" 
                                            :items="listTEMAN2GroupId"
                                            label="Group Id" 
                                            outlined 
                                            hide-details 
                                            dense
                                            @change="changeTEMAN2GroupId($event)"
                                            :disabled="selectItemUser.teman2Activated == 0"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row v-show="selectItemUser.teman2GroupId != null && selectItemUser.teman2GroupId != ''" class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-card
                                            class="pa-3 py-3"
                                            elevation="0"
                                            outlined
                                            >
                                            <v-row>
                                                <v-col 
                                                cols="4"
                                                v-for="(item, i) in listTEMAN2Checkbox"
                                                :key="i"
                                                >
                                                    <v-checkbox
                                                    v-model="item.model"
                                                    :label="item.label"
                                                    :disabled="selectItemUser.teman2Activated == 0"
                                                    ></v-checkbox>
                                                    <!-- {{ item }} -->
                                                </v-col>
                                            </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-list-item-content>
                            </v-card>
                        </v-col>
                     </v-row>

                    
                </v-card-text>

                <v-divider></v-divider>

                <div class="py-3 pr-8">
                    <v-row>
                        <v-spacer></v-spacer>

                            <v-btn
                            class="mr-3 white--text"
                            color="red"
                            @click="closeEditUser()"
                            >CLOSE</v-btn>

                            <v-btn
                            color="primary"
                            @click="addEditUser('Update')"
                            :loading="loadingBtnSubmit"
                            >UPDATE</v-btn>
                    </v-row>
                </div>

            </v-card>
        </v-dialog>

        <!-- Dialog Add User -->
        <v-dialog 
        v-model="dialogAddUser" 
        persistent 
        width="90vh" 
        scrollable>
            <v-card>
                <v-toolbar color="#04994B" dark>
                    Add User

                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeAddUser">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <!-- User Details -->
                    <v-row class="pt-5">
                        <v-col>
                            <v-card 
                            class="mx-auto" 
                            outlined
                            >
                                <v-list-item-content>
                                    <span class="font-weight-bold mb-1 ml-2">
                                        User Details
                                    </span>

                                    <v-row>
                                        <v-col class="pt-0 pb-5">
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>User Id</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'" class="pb-0">
                                            <v-text-field 
                                            v-model="selectItemUser.userId" 
                                            outlined 
                                            dense
                                            maxlength="20"
                                            hint="Maximum 20 characters"
                                            persistent-hint
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col 
                                        align-self="center" 
                                        :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'"
                                        >
                                            <span>Username</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'" class="pb-0">
                                            <v-text-field 
                                            v-model="selectItemUser.username" 
                                            outlined 
                                            dense
                                            maxlength="20"
                                            hint="Maximum 20 characters"
                                            persistent-hint
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col 
                                        :class="$vuetify.breakpoint.lgAndUp ? 'mb-6' : 'mb-0'"
                                        align-self="center" 
                                        :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'"
                                        >
                                            <span>Email</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-text-field 
                                            v-model="selectItemUser.email" 
                                            outlined 
                                            dense
                                            @input="inputEmail($event)"
                                            :error-messages="ErrorEmel"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                </v-list-item-content>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-divider class="my-2"></v-divider>

                    <!-- Indicator CS, WME, RF Button -->

                    <div align="left">
                        <v-row>
                            <v-col>
                                <v-btn class="mr-3" :outlined="(secondMode != 'CS')" color="success"
                                    @click="openCard('CS')">
                                    CS
                                </v-btn>

                                <v-btn class="mr-3" :outlined="(secondMode != 'WME')" color="success"
                                    @click="openCard('WME')">
                                    WME
                                </v-btn>

                                <v-btn class="mr-3" color="success" :outlined="(secondMode != 'RF')" @click="openCard('RF')">
                                    RF
                                </v-btn>

                                <v-btn color="success" :outlined="(secondMode != 'TEMAN2')" @click="openCard('TEMAN2')">
                                    TEMAN 2
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>

                    <!-- CS User Details -->
                    <v-row v-show="secondMode == 'CS'">
                        <v-col>
                            <v-card class="mx-auto" outlined>
                                <v-list-item-content>
                                    <span class="font-weight-bold mb-1 ml-2">
                                        Civil & Structure
                                    </span>

                                    <v-row>
                                        <v-col class="pt-0 pb-5">
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Location</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete 
                                            v-model="selectItemUser.csLocation" 
                                            :items="listCSLocation"
                                            label="Location"
                                            attach
                                            outlined 
                                            hide-details 
                                            dense
                                            :disabled="selectItemUser.userId == '' || selectItemUser.username == '' || selectItemUser.email == ''"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Section / Department</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-text-field 
                                            v-model="selectItemUser.csSection" 
                                            label="Section / Department"
                                            outlined 
                                            hide-details
                                            dense
                                            :disabled="selectItemUser.csLocation == ''"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Group Id</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete 
                                            v-model="selectItemUser.csGroupId" 
                                            :items="listCSGroupId"
                                            label="Group Id"
                                            outlined 
                                            hide-details 
                                            dense
                                            :disabled="selectItemUser.csSection == ''"
                                            @change="changeCSGroupId($event)"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row v-show="selectItemUser.csGroupId != null && selectItemUser.csGroupId != ''" class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-card
                                            class="pa-3 py-3"
                                            elevation="0"
                                            outlined
                                            >
                                            <v-row>
                                                <v-col 
                                                cols="4"
                                                v-for="(item,i) in listCSDropdownCheckbox"
                                                :key="i"
                                                >
                                                    <v-checkbox
                                                    v-model="item.model"
                                                    :label="item.key"
                                                    ></v-checkbox>
                                                    <!-- {{ item }} -->
                                                </v-col>
                                            </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Access Level</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete 
                                            v-model="selectItemUser.csAccessLevel" 
                                            :items="listCSAccessLevel"
                                            label="Access Level" 
                                            outlined 
                                            hide-details 
                                            dense
                                            :disabled="selectItemUser.csGroupId == ''"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>

                                </v-list-item-content>
                            </v-card>
                        </v-col>
                    </v-row>

                    <!-- WME User Details -->
                    <v-row v-show="secondMode == 'WME'">
                        <v-col>
                            <v-card class="mx-auto" outlined>
                                <v-list-item-content>
                                    <span class="font-weight-bold mb-1 ml-2">
                                        Mechanical & Electrical
                                    </span>

                                    <v-row>
                                        <v-col class="pt-0 pb-5">
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Location</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete 
                                            v-model="selectItemUser.wmeLocation" 
                                            :items="listWMELocation"
                                            label="Location" 
                                            outlined 
                                            hide-details 
                                            dense
                                            :disabled="selectItemUser.userId == '' || selectItemUser.username == '' || selectItemUser.email == ''"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Section / Department</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-text-field 
                                            v-model="selectItemUser.wmeSection" 
                                            label="Section / Department"
                                            outlined 
                                            hide-details
                                            dense
                                            :disabled="selectItemUser.wmeLocation == ''"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Group Id</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete 
                                            v-model="selectItemUser.wmeGroupId" 
                                            :items="listWMEGroupId"
                                            label="Group Id" 
                                            outlined 
                                            hide-details 
                                            dense
                                            :disabled="selectItemUser.wmeSection == ''"
                                            @change="changeWMEGroupId($event)"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row v-show="selectItemUser.wmeGroupId != null && selectItemUser.wmeGroupId != ''" class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-card
                                            class="pa-3 py-3"
                                            elevation="0"
                                            outlined
                                            >
                                            <v-row>
                                                <v-col 
                                                cols="4"
                                                v-for="(item,i) in listWMEDropdownCheckbox"
                                                :key="i"
                                                >
                                                    <v-checkbox
                                                    v-model="item.model"
                                                    :label="item.key"
                                                    ></v-checkbox>
                                                    <!-- {{ item }} -->
                                                </v-col>
                                            </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Access Level</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete 
                                            v-model="selectItemUser.wmeAccessLevel" 
                                            :items="listWMEAccessLevel"
                                            label="Access Level" 
                                            outlined 
                                            hide-details 
                                            dense
                                            :disabled="selectItemUser.wmeGroupId == ''"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>

                                </v-list-item-content>
                            </v-card>
                        </v-col>
                    </v-row>

                    <!-- RF User Details -->
                    <v-row v-show="secondMode == 'RF'">
                        <v-col>
                            <v-card class="mx-auto" outlined>
                                <v-list-item-content>
                                    <span class="font-weight-bold mb-1 ml-2">
                                        Road Furniture
                                    </span>

                                    <v-row>
                                        <v-col class="pt-0 pb-5">
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Group Id</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            v-model="selectItemUser.rfGroupId" 
                                            :items="listRFGroupId"
                                            label="Group Id" 
                                            outlined 
                                            hide-details 
                                            dense
                                            :disabled="selectItemUser.userId == '' || selectItemUser.username == '' || selectItemUser.email == ''"
                                            @change="changeRFGroupId($event)"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row v-show="selectItemUser.rfGroupId != null && selectItemUser.rfGroupId != ''" class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-card
                                            class="pa-3 py-3"
                                            elevation="0"
                                            outlined
                                            >
                                            <v-row>
                                                <v-col 
                                                cols="4"
                                                v-for="(item, i) in listRFCheckbox"
                                                :key="i"
                                                >
                                                    <v-checkbox
                                                    v-model="item.model"
                                                    :label="item.label"
                                                    ></v-checkbox>
                                                    <!-- {{ item }} -->
                                                </v-col>
                                            </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                    <!-- <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Activated</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            v-model="selectItemUser.rfActivated" 
                                            :items="listRFActivated"
                                            item-text="label"
                                            outlined 
                                            hide-details
                                            :disabled="selectItemUser.rfGroupId == ''"
                                            @change="changeRFActivated($event)"
                                            dense></v-autocomplete>
                                        </v-col>
                                    </v-row> -->

                                </v-list-item-content>
                            </v-card>
                        </v-col>
                    </v-row>

                    <!-- TEMAN 2 User Details -->
                    <v-row v-show="secondMode == 'TEMAN2'">
                        <v-col>
                            <v-card class="mx-auto" outlined>
                                <v-list-item-content>
                                    <span class="font-weight-bold mb-1 ml-2">
                                        Teman 2
                                    </span>

                                    <v-row>
                                        <v-col class="pt-0 pb-5">
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>

                                    <v-row class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                            <span>Group Id</span>
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-autocomplete
                                            v-model="selectItemUser.teman2GroupId"
                                            :items="listTEMAN2GroupId"
                                            label="Group Id"
                                            outlined
                                            hide-details
                                            dense
                                            :disabled="selectItemUser.userId == '' || selectItemUser.username == '' || selectItemUser.email == ''"
                                            @change="changeTeman2GroupId($event)"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>

                                    <v-row v-show="selectItemUser.teman2GroupId != null && selectItemUser.teman2GroupId != ''" class="px-5">
                                        <v-col align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? '4' : '12'">
                                        </v-col>

                                        <v-col :cols="$vuetify.breakpoint.lgAndUp ? '8' : '12'">
                                            <v-card
                                            class="pa-3 py-3"
                                            elevation="0"
                                            outlined
                                            >
                                            <v-row>
                                                <v-col 
                                                cols="4"
                                                v-for="(item, i) in listTEMAN2Checkbox"
                                                :key="i"
                                                >
                                                    <v-checkbox
                                                    v-model="item.model"
                                                    :label="item.label"
                                                    ></v-checkbox>
                                                    <!-- {{ item }} -->
                                                </v-col>
                                            </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-list-item-content>
                            </v-card>
                        </v-col>
                    </v-row>


                </v-card-text>

                <v-divider></v-divider>

                <div class="py-3 pr-8">
                    <v-row>
                        <v-spacer></v-spacer>

                        <v-btn
                        class="mr-3 white--text"
                        color="red"
                        @click="closeAddUser()"
                        >
                        CLOSE
                        </v-btn>

                        <v-btn color="primary" 
                        @click="addEditUser('Save')"
                        :loading="loadingBtnSubmit"
                        :disabled="
                        (selectItemUser.userId == '' || selectItemUser.username == '' || selectItemUser.email == '' || this.ErrorEmel == true)
                        || selectItemUser.csAccessLevel == '' && selectItemUser.wmeAccessLevel == '' && selectItemUser.groupid == ''"
                        >
                        SAVE
                        </v-btn>
                    </v-row>
                </div>

            </v-card>
        </v-dialog>

    </div>
    
</template>

<script>
import axios from 'axios';
import { bus } from '@/main';

export default {

    data: () => ({
        firstMode: 'userList',
        secondMode: 'CS',

        searchTable: '',
        loadingTable: false,

        ErrorEmel: "",

        loadingBtnSubmit: false,


        thead: [
            {
                text:'Username', 
                value:'username', 
                sortable: true, 
                divider:false
            },
            {
                text:'CS', 
                value:'cs', 
                sortable: false, 
                divider:false
            },
            {
                text:'WME', 
                value:'wme', 
                sortable: false, 
                divider:false
            },
            {
                text: 'RF',
                value: 'rf',
                sortable: false,
                divider: false
            },
            {
                text: 'TEMAN 2',
                value: 'teman2',
                sortable: false,
                divider: false
            },
            {
                text:'Action', 
                value:'action', 
                sortable: false, 
                divider:true, 
                align:'center'
            },
        ],

        tbody: [],

        // Dialog Edit User
        dialogEditUser: false,

        // Dialog Add User
        dialogAddUser: false,

        selectItemUser: {
            userId: "",
            username: "",
            email: "",

            csLocation: "",
            csSection: "",
            csGroupId: "",
            csAccessLevel: "",
            csActivated:"",

            wmeLocation: "",
            wmeSection: "",
            wmeGroupId: "",
            wmeAccessLevel: "",
            wmeActivated: "",

            rfGroupId: "",
            rfActivated: "",

            teman2GroupId: "",
            teman2Activated: "",
            
        },


        // List Dropdown CS
        listDataCS:[],
        listCSLocation:[],
        listCSGroupId:[],
        listCSAccessLevel:[],
        listCSCheckbox: [],

        // List Dropdown WME
        listDataWME:[],
        listWMELocation:[
            // {
            //     location:"",
            //     value:"",
            // }
        ],
        listWMEGroupId:[],
        listWMEAccessLevel:[],
        listWMECheckbox: [],
        wmeData: [],


        // List Dropdown RF
        listDataRF:[],
        listRFGroupId:[],
        // listRFTempGroupId:[],
        listTempRFCheckbox:[],
        listActivated:[
            {
                label: 'Activated',
                value: 1
            },
            {
                label: 'Deactivated',
                value: 0
            },
        ],
        listRFCheckbox: [
            {
                label: 'Add data',
                key:'add_inventory_data',
                key2: 'add_data',
                model:'',
                id:''
            },
            {
                label: 'Delete data',
                key: 'delete_inventory_data',
                key2: 'delete_data',
                model: '',
                id: '',
            },
            {
                label: 'Edit data',
                key: 'edit_inventory_data',
                key2: 'edit_data',
                model: '',
                id: '',
            },
            {
                label: 'View data',
                key: 'view_inventory_data',
                key2: 'view_data',
                model: '',
                id: '',
            },
        ],
        rfData: [],
        rfOriginalData: [],
        dataGroupIDSelected: null,

        // List Dropdown Teman 2
        listDataTEMAN2: [],
        listTEMAN2GroupId:[],
        listTempTEMAN2Checkbox: [],
        listTEMAN2Checkbox: [
            {
                label: 'Add data',
                key:'add_inventory_data',
                key2: 'add_data',
                model:'',
                id:''
            },
            {
                label: 'Delete data',
                key: 'delete_inventory_data',
                key2: 'delete_data',
                model: '',
                id: '',
            },
            {
                label: 'Edit data',
                key: 'edit_inventory_data',
                key2: 'edit_data',
                model: '',
                id: '',
            },
            {
                label: 'View data',
                key: 'view_inventory_data',
                key2: 'view_data',
                model: '',
                id: '',
            },
        ],
        teman2Data: [],
        teman2OriginalData: [],
        dataTeman2GroupIDSelected: null,


        // List Dropdown Checkbox
        listCSDropdownCheckbox:[],
        listWMEDropdownCheckbox:[],
        // listRFDropdownCheckbox:[],

        selectedId: null,
        selectedIdTEMAN2: null,

        countCS: 0,
        countWME: 0,
        countRF: 0,
        countTEMAN2: 0,
        

    }),

    mounted() {

        this.getTableUserList();
        this.loadAllDropDown();

        // console.log(this.listRFGroupId);
        // console.log(this.selectItemUser.rfGroupId);

    },

    methods: {

        getTableUserList()
        {
            this.loadingTable = true;
            this.tbody = [];

            axios.get(this.vueUrl + 'callapi/allusers.php', {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.user.id_token,
                },
            })
            .then((response) => {
                let data = response.data;

                this.tbody = data

                // for(let i in data)
                // {
                //     this.tbody.push(data[i])
                // }

                this.loadingTable = false;

            })
            .catch((error) => { //eslint-disable-line
                console.log(error);

                this.loadingTable = false;

            });
        },

        loadAllDropDown()
        {
            axios.get(this.vueUrl + 'callapi/alldropdown.php', {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.user.id_token,
                },
            })
            .then((response) => {
                let data = response.data;


                this.listDataCS = data.cs;
                this.listDataWME = data.wme;
                this.listDataRF = data.rf;
                this.listDataTEMAN2 = data.teman2;

                // CS Dropdown
                this.listCSLocation = this.listDataCS.location;
                this.listCSGroupId = this.listDataCS.groupid;
                this.listCSAccessLevel = this.listDataCS.accesslevel;
                this.listCSCheckbox = this.listDataCS.groupidcekbox;

                // console.log(this.listCSCheckbox);
                

                // WME Dropdown

                // console.log(this.listDataWME.location);
                this.listWMECheckbox = this.listDataWME.groupidcekbox;

                for(let w in this.listDataWME.location)
                {
                    this.listWMELocation.push(this.listDataWME.location[w].LOCATION);
                    // for(let u in this.listWMELocation)
                    // {
                    //     this.listWMELocation[u].location = this.listDataWME.location[w].LOCATION;
                    //     this.listWMELocation[u].value = this.listDataWME.location[w].ID;
                    // }
                    
                }

                // console.log(this.listWMELocation);

                // console.log(this.listDataWME.location);

                for(let m in this.listDataWME.groupid)
                {
                    this.listWMEGroupId.push(this.listDataWME.groupid[m]);
                }

                for(let e in this.listDataWME.accesslevel)
                {
                    this.listWMEAccessLevel.push(this.listDataWME.accesslevel[e]);
                }



                // RF Dropdown

                // console.log(this.listDataRF);

                for(let r in this.listDataRF.groupid)
                {
                    // console.log(this.listDataRF.groupid[r]);
                    this.listRFGroupId.push(this.listDataRF.groupid[r].name)
                    // console.log(this.listDataRF.groupid[r].id);
                }

                this.listTempRFCheckbox = this.listDataRF.groupipcekbox;

                // console.log(this.listTempRFCheckbox);

                // console.log(this.listDataRF.groupid);
                // console.log("_________________________________");
                // console.log(this.listTempRFCheckbox);

                // console.log(this.listTempRFCheckbox[f]);
                // for(let f in this.listTempRFCheckbox)
                // {
                //     let keys = Object.keys(this.listTempRFCheckbox[f])
                //     console.log(keys);

                // }
                
                // console.log(this.listDataRF);
                // console.log(this.listRFCheckbox);

                // console.log(this.listDataRF.groupid);
                // console.log(this.listRFGroupId);
                // for(let f in this.listRFGroupId)
                // {
                //     for(let u in this.listDataRF.groupid)
                //     {
                //         if(this.listRFGroupId[f] == this.listDataRF.groupid[u].name)
                //         {
                //             console.log("hello");
                //         }
                //     }
                // }

                // TEMAN 2 Dropdown

                for(let t in this.listDataTEMAN2.groupid){

                    this.listTEMAN2GroupId.push(this.listDataTEMAN2.groupid[t].name)
                    
                }

                this.listTempTEMAN2Checkbox = this.listDataTEMAN2.groupipcekbox;


            })
            .catch((error) => { //eslint-disable-line
                console.log(error);

                // this.loadingTable = false;

            });
        },

        csFunction()
        {

            if(location.protocol == "https:"){
                window.location.href ="https://teman.plus.com.my/webteman/apis1/redirectmenuaccess?id_token="+ this.$store.getters.user.id_token;
            }
            else{
                window.location.href ="http://teman.plus.com.my/webteman/apis1/redirectmenuaccess?id_token="+ this.$store.getters.user.id_token;
            }
            
        },

        wmeFunction()
        {
            window.location.href = "http://temanwme.plus.com.my/wme/apis1/redirectmenuaccess?id_token=" + this.$store.getters.user.id_token;

        },

        inputEmail(input)
        {
            if (input.length == 0) {
                this.ErrorEmel = "";
            }
            else {
                if (input.includes("@") == true) {
                    // console.log(input.split("@"));
                    if (input.split("@")[1].at(0) == ".") {
                        this.ErrorEmel = "Please insert correct email";

                    }
                    else {

                        if (input.split("@")[1].includes(".com") ||
                            input.split("@")[1].includes(".tech") ||
                            input.split("@")[1].includes(".gov") ||
                            input.split("@")[1].includes(".edu") ||
                            input.split("@")[1].includes(".net") ||
                            input.split("@")[1].includes(".org") ||
                            input.split("@")[1].includes(".mil") ||
                            input.split("@")[1].includes(".co") ||
                            input.split("@")[1].includes(".my")) {
                            this.ErrorEmel = "";
                        }
                        else {
                            this.ErrorEmel = "Please insert correct email";
                        }
                    }

                }
                else {
                    this.ErrorEmel = "Please insert correct email";
                }
            }
        },

        changeCSGroupId(data)
        {
            
            this.listCSDropdownCheckbox = [];
            let tempCheckbox1 = [];
            let tempCheckbox2 = [];

            for(let i in this.listCSCheckbox)
            {

                let csKey = Object.keys(this.listCSCheckbox[i])

                for(let y in csKey)
                {
                
                    if(data == csKey[y])
                    {

                        tempCheckbox1.push(this.listCSCheckbox[i][csKey[y]]);

                        for(let x in tempCheckbox1)
                        {
                            tempCheckbox2 = tempCheckbox1[0];

                            let tempCheckbox2Keys = Object.keys(tempCheckbox2);

                            for(let w in tempCheckbox2Keys)
                            {
                                // console.log(tempCheckbox2Keys[w]);
                                this.listCSDropdownCheckbox.push(
                                    {
                                        key: tempCheckbox2Keys[w],
                                        model: tempCheckbox1[x][tempCheckbox2Keys[w]],
                                    }
                                )
                            }
                            
                        }

                    }
                    
                }
            }

            // let selectedGroupId = this.selectItemUser.csGroupId;
            // let csObject = {};

            // csObject[selectedGroupId] = {}
            
            // console.log(csObject);
            

        },

        changeWMEGroupId(data)
        {
            this.listWMEDropdownCheckbox = [];

            let tempCheckbox1 = [];
            let tempCheckbox2 = [];

            for (let i in this.listWMECheckbox) {

                let wmeKey = Object.keys(this.listWMECheckbox[i])

                for (let y in wmeKey) {

                    if (data == wmeKey[y]) {

                        tempCheckbox1.push(this.listWMECheckbox[i][wmeKey[y]]);

                        // console.log(tempCheckbox1);

                        for (let x in tempCheckbox1) {
                            tempCheckbox2 = tempCheckbox1[0];

                            let tempCheckbox2Keys = Object.keys(tempCheckbox2);

                            for (let w in tempCheckbox2Keys) {
                                // console.log(tempCheckbox2Keys[w]);
                                this.listWMEDropdownCheckbox.push(
                                    {
                                        key: tempCheckbox2Keys[w],
                                        model: tempCheckbox1[x][tempCheckbox2Keys[w]],
                                    }
                                )
                            }

                        }

                    }
                }
            }

            // console.log(this.listWMEDropdownCheckbox);
        },

        changeRFGroupId(data)
        {

            // console.log("lollllllllll");
            // console.log(data);

            if(data == undefined)
            {
                this.selectItemUser.rfGroupId = '';
            }

            else
            {
                // console.log(this.listDataRF);
            // console.log(data);

            let dataOriginalRoles = this.listDataRF.groupid;
            let dataOriginalKeys = this.listDataRF.groupipcekbox;

            for(let i in dataOriginalRoles){
                if(dataOriginalRoles[i].name?.toUpperCase() == data?.toUpperCase()){
                    this.dataGroupIDSelected = dataOriginalRoles[i]
                }
            }

            // console.log(this.dataGroupIDSelected);

            this.selectedId = this.dataGroupIDSelected.id

            let keys1 = Object.keys(this.dataGroupIDSelected);

            // console.log(keys1);

            for(let i in dataOriginalRoles){
                for(let x in this.listRFCheckbox){
                    for(let z in keys1){
                        if (this.listRFCheckbox[x].key2 == keys1[z]){

                            if(dataOriginalRoles[i].id == this.dataGroupIDSelected.id){
                                this.listRFCheckbox[x].model = dataOriginalRoles[i][keys1[z]] == 0 ? false : true;
                            }
                            
                        }
                    }
                    
                }
            }
            }
            

            // console.log("lastt test",this.listRFCheckbox);
        },

        changeTEMAN2GroupId(data)
        {

            // console.log("lollllllllll");
            // console.log(data);

            if(data == undefined)
            {
                this.selectItemUser.teman2GroupId = '';
            }

            else
            {
                // console.log(this.listDataRF);
            // console.log(data);

            let dataOriginalRoles = this.listDataTEMAN2.groupid;
            let dataOriginalKeys = this.listDataTEMAN2.groupipcekbox;

            for(let i in dataOriginalRoles){
                if(dataOriginalRoles[i].name?.toUpperCase() == data?.toUpperCase()){
                    this.dataTeman2GroupIDSelected = dataOriginalRoles[i]
                }
            }

            // console.log(this.dataGroupIDSelected);

            this.selectedIdTEMAN2 = this.dataTeman2GroupIDSelected.id

            let keys1 = Object.keys(this.dataTeman2GroupIDSelected);

            // console.log(keys1);

            for(let i in dataOriginalRoles){
                for(let x in this.listTEMAN2Checkbox){
                    for(let z in keys1){
                        if (this.listTEMAN2Checkbox[x].key2 == keys1[z]){

                            if(dataOriginalRoles[i].id == this.dataTeman2GroupIDSelected.id){
                                this.listTEMAN2Checkbox[x].model = dataOriginalRoles[i][keys1[z]] == 0 ? false : true;
                            }
                            
                        }
                    }
                    
                }
            }
            }
            

            // console.log("lastt test",this.listRFCheckbox);
        },

        changeRFActivated(data)
        {
            // console.log(data);
        },

        changeTEMAN2Activated(data){

        },

        clickLink(data)
        {
            // console.log(data);

            this.dialogEditUser = true;
            this.secondMode = 'CS';

            let name = data.username

            axios.get(this.vueUrl + 'callapi/userinfo.php?userid=' + name, {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.user.id_token,
                },
            })
                .then((response) => {
                    let data = response.data;

                    // console.log(data);

                    let csData = data.cs;
                    this.wmeData = data.wme;
                    this.rfData = data.rf;
                    this.rfOriginalData = data.rf;
                    this.teman2Data = data.teman2;
                    this.teman2OriginalData = data.teman2;

                    // console.log(this.rfData);

                    if(csData != null)
                    {
                        this.selectItemUser.userId = csData.userid;
                        this.selectItemUser.username = csData.username;
                        this.selectItemUser.email = csData.emel;

                        // CS DATA
                        this.selectItemUser.csLocation = csData.location;
                        this.selectItemUser.csSection = csData.section_or_department;
                        this.selectItemUser.csGroupId = csData.groupid;
                        this.selectItemUser.csAccessLevel = csData.accesslevel;
                        this.selectItemUser.csActivated = csData.activated;

                        // console.log(this.selectItemUser.csLocation);

                        // console.log(this.selectItemUser.csGroupId);
                        this.listCSDropdownCheckbox = [];

                        let tempCheckbox1 = [];
                        let tempCheckbox2 = [];

                        // console.log(this.selectItemUser.csGroupId);

                        // console.log(Object.keys(csData.groupid_cekbox)[0]);
                        if (this.selectItemUser.csGroupId == Object.keys(csData.groupid_cekbox)[0]) {
                            for (let i in csData.groupid_cekbox) {
                                // console.log(csData.groupid_cekbox[i]);
                                tempCheckbox1.push(csData.groupid_cekbox[i]);

                                // console.log(tempCheckbox1);

                                for (let y in tempCheckbox1) {
                                    tempCheckbox2 = tempCheckbox1[0];

                                    let tempCheckbox2Keys = Object.keys(tempCheckbox2);

                                    for (let x in tempCheckbox2Keys) {
                                        this.listCSDropdownCheckbox.push(
                                            {
                                                key: tempCheckbox2Keys[x],
                                                model: tempCheckbox1[y][tempCheckbox2Keys[x]],
                                            })
                                    }
                                }
                            }
                        }
                    }

                    else if(csData == null)
                    {
                        if(this.wmeData != null)
                        {
                            this.selectItemUser.userId = this.wmeData.userid;
                            this.selectItemUser.username = this.wmeData.username;
                            this.selectItemUser.email = this.wmeData.emel;
                        }

                        else if(this.wmeData == null)
                        {
                            this.selectItemUser.userId = this.rfData.userid;
                            this.selectItemUser.username = this.rfData.username;
                            this.selectItemUser.email = this.rfData.emel;
                        }
                    }


                })
                .catch((error) => { //eslint-disable-line
                    console.log(error);

                    // this.loadingTable = false;

                });

        },

        closeEditUser()
        {
            this.csData = null;
            this.wmeData = null;
            this.rfData = null;
            this.teman2Data = null;

            this.dialogEditUser = false;

            for(let i in this.selectItemUser)
            {
                this.selectItemUser[i] = [];

            }
                // console.log(this.selectItemUser);


            for(let y in this.listRFCheckbox)
            {
                this.listRFCheckbox[y].model = '';
            }

            for(let t in this.listTEMAN2Checkbox)
            {
                this.listTEMAN2Checkbox[t].model = '';
            }

            this.countCS = 0;
            this.countWME = 0;
            this.countRF = 0;
            this.countTEMAN2 = 0;

        },

        addUser()
        {
            // console.log("Hello world");
            this.dialogAddUser = true;

            for (let i in this.selectItemUser) 
            {
                this.selectItemUser[i] = [];
            }

            // console.log("NI CLEAR",this.selectItemUser);


            this.secondMode = 'CS';
        },

        closeAddUser()
        {
            this.dialogAddUser = false;
            this.ErrorEmel= "";

            for (let i in this.selectItemUser) 
            {
                this.selectItemUser[i] = [];
            }

            this.countCS = 0;
            this.countWME = 0;
            this.countRF = 0;
            this.countTEMAN2 = 0;

        },

        openCard(data)
        {
            // this.dialogAddUser = true;

            // console.log("whyyyyyyyyyyyy?",data);
            
            if(data == 'CS')
            {  
                this.countCS++;
                this.secondMode = "CS";
                if(this.countCS == 0)
                {
                    this.changeCSGroupId(this.selectItemUser.group_fullname);
                }
            }

            else if(data == 'WME')
            {
                this.countWME++;

                // console.log("NI COUNT WME",this.countWME);

                // console.log("count",this.countWME);
                // console.log(this.wmeData);

                this.secondMode = "WME";
                if(this.countWME == 1)
                {
                    
                    this.changeWMEGroupId(this.selectItemUser.wmeGroupId);


                    if (this.wmeData != null && this.wmeData.length != 0) {
                        // console.log("HELLO");
                        this.selectItemUser.userId = this.wmeData.userid;
                        this.selectItemUser.username = this.wmeData.username;
                        this.selectItemUser.email = this.wmeData.emel;
                        this.selectItemUser.wmeSection = this.wmeData.section_or_department;
                        this.selectItemUser.wmeGroupId = this.wmeData.groupid;
                        this.selectItemUser.wmeAccessLevel = this.wmeData.accesslevel;
                        this.selectItemUser.wmeActivated = this.wmeData.activated;

                        // console.log(this.wmeData);

                        // WME DATA
                        this.listWMEDropdownCheckbox = [];

                        let tempmweCheckbox1 = [];
                        let tempwmeCheckbox2 = [];

                        // console.log(this.selectItemUser.wmeGroupId);

                        // console.log(Object.keys(csData.groupid_cekbox)[0]);
                        if (this.selectItemUser.wmeGroupId == Object.keys(this.wmeData.groupid_cekbox)[0]) {
                            for (let i in this.wmeData.groupid_cekbox) {
                                // console.log(csData.groupid_cekbox[i]);
                                tempmweCheckbox1.push(this.wmeData.groupid_cekbox[i]);

                                // console.log(tempCheckbox1);

                                for (let y in tempmweCheckbox1) {
                                    tempwmeCheckbox2 = tempmweCheckbox1[0];

                                    let tempwmeCheckbox2Keys = Object.keys(tempwmeCheckbox2);

                                    for (let x in tempwmeCheckbox2Keys) {
                                        this.listWMEDropdownCheckbox.push(
                                            {
                                                key: tempwmeCheckbox2Keys[x],
                                                model: tempmweCheckbox1[y][tempwmeCheckbox2Keys[x]],
                                            })
                                    }
                                }
                            }
                        }

                        // this.selectItemUser.wmeLocation = wmeData.location;
                        // let locationKey = Object.keys(this.listDataWme.location)
                        // {
                        //     console.log(locationKey);
                        // }
                        // console.log(this.listDataWME.location);
                        for (let w in this.listDataWME.location) {
                            // console.log(this.listDataWME.location[w].ID);

                            if (this.wmeData.location == this.listDataWME.location[w].ID) {
                                this.selectItemUser.wmeLocation = this.listDataWME.location[w].LOCATION
                            }

                            // console.log(this.selectItemUser.wmeLocation);
                        }

                        // console.log(wmeData.location);

                        // console.log(this.selectItemUser.wmeLocation);

                        // console.log("HAII", this.listWMEDropdownCheckbox);
                    }
                    
                    
                }

                
            }

            else if(data == 'RF')
            {
                this.countRF++;

                // console.log("Countttt",this.countRF);

                // console.log(this.rfData);

                // console.log(this.dataGroupIDSelected);

                this.secondMode = "RF";
                if(this.countRF == 1)
                {
                    
                    // console.log("firstttttttttttt RF");
                    // console.log(this.rfData);
                    this.changeRFGroupId(this.rfOriginalData.group_fullname);

                    if (this.rfData != null && this.rfData.length != 0) {
                        // console.log(this.rfData);

                        this.selectItemUser.userId = this.rfData.userid;
                        this.selectItemUser.username = this.rfData.username;
                        this.selectItemUser.email = this.rfData.emel;
                        this.selectItemUser.rfActivated = this.rfData.activated;


                        // RF DATA
                        // Group ID
                        // console.log(this.listRFGroupId);
                        // console.log(this.rfData);
                        // console.log("----------------------------------------------");

                        // let key = Object.keys(this.listRFGroupId)
                        for (let i in this.listRFGroupId) {
                            // console.log(this.listRFGroupId[i].toUpperCase());
                            // console.log(rfData.group_fullname.toUpperCase());
                            if (this.listRFGroupId[i]?.toUpperCase() == this.rfData.group_fullname?.toUpperCase()) {

                                // console.log("hello world");
                                this.selectItemUser.rfGroupId = this.listRFGroupId[i];
                                this.selectedId = this.rfData.groupid;
                                // console.log(this.listRFGroupId[key[i]]);
                            }
                        }

                        // console.log(this.selectItemUser.rfGroupId);

                        // Checkbox
                        // console.log(this.listRFCheckbox);
                        // console.log("--------------");
                        // console.log(this.selectedId);
                        // console.log(this.rfData.groupid_cekbox[this.rfData.groupid]);
                        // console.log(this.listRFCheckbox);

                        let tempID = this.rfData.groupid

                        let checkboxkey = Object.keys(this.rfData.groupid_cekbox[tempID])

                        // console.log(checkboxkey);

                        for (let x in this.listRFCheckbox) {

                            for (let z in checkboxkey) {
                                // console.log(checkboxkey[z]);
                                if (checkboxkey[z] == this.listRFCheckbox[x].key) {

                                    // console.log("helloooooooooo");
                                    // console.log(checkboxkey[z]);
                                    // console.log(this.listRFCheckbox[x].key);
                                    this.listRFCheckbox[x].model = this.rfData.groupid_cekbox[this.rfData.groupid][checkboxkey[z]];

                                }
                            }
                        }
                        // console.log(rfData.groupid_cekbox[y]);

                    }
                    
                    

                }

                

                // console.log("$$$$$$$$$$$$$$$", this.listRFCheckbox);

                // console.log(this.selectItemUser.rfGroupId);
            }

            else if(data == 'TEMAN2'){

                this.countTEMAN2++;
                this.secondMode = "TEMAN2";

                if(this.countTEMAN2 == 1)
                {
                    
                    this.changeTEMAN2GroupId(this.teman2OriginalData.group_fullname);

                    if (this.teman2Data != null && this.teman2Data.length != 0) {
                        // console.log(this.rfData);

                        this.selectItemUser.userId = this.teman2Data.userid;
                        this.selectItemUser.username = this.teman2Data.username;
                        this.selectItemUser.email = this.teman2Data.emel;
                        this.selectItemUser.teman2Activated = this.teman2Data.activated;

                        for (let i in this.listTEMAN2GroupId) {
                            
                            if (this.listTEMAN2GroupId[i]?.toUpperCase() == this.teman2Data.group_fullname?.toUpperCase()) {

                                // console.log("hello world");
                                this.selectItemUser.teman2GroupId = this.listTEMAN2GroupId[i];
                                this.selectedIdTEMAN2 = this.teman2Data.groupid;
                            }
                        }

                        let tempID = this.teman2Data.groupid

                        let checkboxkey = Object.keys(this.teman2Data.groupid_cekbox[tempID])

                        // console.log(checkboxkey);

                        for (let x in this.listTEMAN2Checkbox) {

                            for (let z in checkboxkey) {
                                // console.log(checkboxkey[z]);
                                if (checkboxkey[z] == this.listTEMAN2Checkbox[x].key) {

                                    // console.log("helloooooooooo");
                                    // console.log(checkboxkey[z]);
                                    // console.log(this.listRFCheckbox[x].key);
                                    this.listTEMAN2Checkbox[x].model = this.teman2Data.groupid_cekbox[this.teman2Data.groupid][checkboxkey[z]];

                                }
                            }
                        }
                        // console.log(rfData.groupid_cekbox[y]);

                    }
                }

            }

            // console.log("CS",this.countCS);
            // console.log("WME",this.countWME);
            // console.log("RF",this.countRF);
            // console.log("TEMAN2",this.countTEMAN2);
        },

        addEditUser(event)
        {
            // console.log(event);
            if(event == 'Save')
            {
                this.selectItemUser.csActivated = 1;
                this.selectItemUser.wmeActivated = 1;
                this.selectItemUser.rfActivated = 1;
                this.selectItemUser.teman2Activated = 1;
            }
            // console.log(event);
            // let groupid_cekbox = null;
            // CS DETAILS
            let selectedcsGroupId = this.selectItemUser.csGroupId;
            // console.log(selectedcsGroupId);
            let groupid_cscekbox = {};

            groupid_cscekbox[selectedcsGroupId] = {};
            
            let cskeys = Object.keys(groupid_cscekbox)
            for(let i in this.listCSDropdownCheckbox){
                for(let x in cskeys)
                {
                    groupid_cscekbox[cskeys[x]][this.listCSDropdownCheckbox[i].key] = this.listCSDropdownCheckbox[i].model
                }
            }

            // console.log(selectedGroupId);

            // WME DETAILS
            let selectedwmeGroupId = this.selectItemUser.wmeGroupId;
            let groupid_wmecekbox = {};
            let wmekeyLocation = '';

            for(let j in this.listDataWME.location)
            {
                if(this.selectItemUser.wmeLocation == this.listDataWME.location[j].LOCATION)
                {
                    wmekeyLocation = this.listDataWME.location[j].ID;
                }
            }

            groupid_wmecekbox[selectedwmeGroupId] = {};

            let wmekeys = Object.keys(groupid_wmecekbox)

            // console.log(wmekeys);

            for (let i in this.listWMEDropdownCheckbox) {
                for (let x in wmekeys) 
                {
                    groupid_wmecekbox[wmekeys[x]][this.listWMEDropdownCheckbox[i].key] = this.listWMEDropdownCheckbox[i].model
                }
            }

            // console.log(groupid_cscekbox);
            // console.log(this.listWMEDropdownCheckbox);

            // RF DETAILS
            // let selectedrfGroupId = this.selectItemUser.rfGroupId;
            // if (event == 'Update') 
            // {
            //     this.selectedId = this.selectItemUser.rfGroupId;
            // }
            let groupid_rfcekbox = {};

            // console.log(this.selectedId);

            // console.log(this.selectItemUser.rfGroupId);

            // groupid_rfcekbox[this.selectedId] = {};

            // console.log("nuzul", this.rfData);

            for (let r in this.listTempRFCheckbox)
            {
                let rfKeys = Object.keys(this.listTempRFCheckbox[r])
                
                // console.log(rfKeys);

                if(this.selectedId == rfKeys)
                {
                    groupid_rfcekbox = this.listTempRFCheckbox[r]
                }
                
            }

            // console.log("check ni");
            // console.log(groupid_rfcekbox);



            // console.log("ni object key");
            // console.log(objectKeyGroupIdCheckBox);

            for(let f in this.listRFCheckbox)
            {
                for(let k in groupid_rfcekbox)
                {
                    let objectKeyGroupIdCheckBox = Object.keys(groupid_rfcekbox[k])

                    for(let t in objectKeyGroupIdCheckBox)
                    {
                        if(this.listRFCheckbox[f].key == objectKeyGroupIdCheckBox[t])
                        {
                            groupid_rfcekbox[k][objectKeyGroupIdCheckBox[t]] = this.listRFCheckbox[f].model;
                        }
                    }

                }
            }

            // TEMAN2 DETAILS
            let groupid_teman2cekbox = {};

            for (let t in this.listTempTEMAN2Checkbox)
            {
                let teman2Keys = Object.keys(this.listTempTEMAN2Checkbox[t])
                
                if(this.selectedIdTEMAN2 == teman2Keys)
                {
                    groupid_teman2cekbox = this.listTempTEMAN2Checkbox[t]
                }
                
            }

            for(let e in this.listTEMAN2Checkbox)
            {
                for(let m in groupid_teman2cekbox)
                {
                    let objectKeyGroupIdCheckBox = Object.keys(groupid_teman2cekbox[m])

                    for(let n in objectKeyGroupIdCheckBox)
                    {
                        if(this.listTEMAN2Checkbox[e].key == objectKeyGroupIdCheckBox[n])
                        {
                            groupid_teman2cekbox[m][objectKeyGroupIdCheckBox[n]] = this.listTEMAN2Checkbox[e].model;
                        }
                    }

                }
            }

            // console.log("ni update rfcekbox");
            // console.log(groupid_rfcekbox);



            // console.log("---------------------------------------------------");
            // console.log(this.listRFDropdownCheckbox);


            let checkArray = [
                {
                    key: 'cs',
                    details: groupid_cscekbox
                },
                {
                    key: 'wme',
                    // details: groupid_wmecekbox[selectedwmeGroupId]
                    details: groupid_wmecekbox
                },
                {
                    key: 'rf',
                    // details: groupid_rfcekbox[selectedrfGroupId]
                    details: groupid_rfcekbox
                },
                {
                    key: 'teman2',
                    // details: groupid_rfcekbox[selectedrfGroupId]
                    details: groupid_teman2cekbox
                }
            ]

            // console.log("arrray",checkArray);

            let payload = {
                cs:{
                    userid: this.selectItemUser.userId,
                    username: this.selectItemUser.username,
                    location: this.selectItemUser.csLocation,
                    section_or_department: this.selectItemUser.csSection,
                    groupid: this.selectItemUser.csGroupId,
                    accesslevel: this.selectItemUser.csAccessLevel,
                    emel: this.selectItemUser.email,
                    groupid_cekbox: null,
                    activated: this.selectItemUser.csActivated,
                },
                wme:{
                    userid: this.selectItemUser.userId,
                    username: this.selectItemUser.username,
                    location: wmekeyLocation,
                    section_or_department: this.selectItemUser.wmeSection,
                    groupid: this.selectItemUser.wmeGroupId,
                    accesslevel: this.selectItemUser.wmeAccessLevel,
                    emel: this.selectItemUser.email,
                    groupid_cekbox: null,
                    activated: this.selectItemUser.wmeActivated,
                },
                rf:{
                    userid: this.selectItemUser.userId,
                    username: this.selectItemUser.username,
                    emel: this.selectItemUser.email,
                    groupid: this.selectedId,
                    groupid_cekbox: null,
                    activated: this.selectItemUser.rfActivated,

                },
                teman2:{
                    userid: this.selectItemUser.userId,
                    username: this.selectItemUser.username,
                    emel: this.selectItemUser.email,
                    groupid: this.selectedIdTEMAN2,
                    groupid_cekbox: null,
                    activated: this.selectItemUser.teman2Activated,

                }
            }

            // console.log("------------------------------");
            // console.log(payload);
            // console.log(checkArray);

            let payloadKey = Object.keys(payload)

            for(let i in payloadKey)
            {
                 for (let y in checkArray) 
                {
                    if(payloadKey[i] == checkArray[y].key)
                    {
                        // console.log("heyyyyyyy");
                        // groupid_cekbox = checkArray[y][payloadKey[y]]
                        // console.log(checkArray[i].details);
                        payload[payloadKey[i]].groupid_cekbox = checkArray[y].details;
                    }
                }
            }

            if(this.selectItemUser.csAccessLevel == '')
            {
                payload.cs = null;
            }
            if(this.selectItemUser.wmeAccessLevel == '')
            {
                payload.wme = null;
            }
            if(this.selectItemUser.rfGroupId == '')
            {
                payload.rf = null;
            }
            if(this.selectItemUser.teman2GroupId == '')
            {
                payload.teman2 = null;
            }
           
            // console.log("this is payloaddddddddd",payload);
   
            this.loadingBtnSubmit = true;

            // console.log(this.listTempRFCheckbox);

            axios.put(this.vueUrl + 'callapi/addedituser.php' , payload, {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.user.id_token,
                },
            })
            .then((response) => {
                let data = response.data;

                if(event == 'Save')
                {

                    bus.$emit('popupMessage', {
                        result: "success",
                        icon: "mdi-file-document-check",
                        title: "Add User",
                        text: "User has been successfully added",
                        // nota: "refresh page"
                    });

                    this.countCS = 0;
                    this.countWME = 0;
                    this.countRF = 0;
                    this.countTEMAN2 = 0;

                    this.closeAddUser();
                    this.getTableUserList();
                    // this.resetPage();
                }
                else if(event == 'Update')
                {
                    bus.$emit('popupMessage', {
                        result: "success",
                        icon: "mdi-file-document-check",
                        title: "Update User",
                        text: "User has been successfully updated",
                        // nota: "refresh page"
                    });

                    this.countCS = 0;
                    this.countWME = 0;
                    this.countRF = 0;
                    this.countTEMAN2 = 0;

                    this.closeEditUser();
                    this.getTableUserList();
                    // this.resetPage();


                    // this.$store.getters.user.role_detail.   wme = this.selectItemUser.wmeActivated == "Activated"? true : false

                    // console.log("haii",this.selectItemUser.wmeActivated == "Activated"? true : false);
                    // console.log("editttt success",this.$store.getters.user);
                    
                    this.$store.dispatch("updateSessionVue");

                }

                

                this.loadingBtnSubmit = false;


            })
            .catch((error) => { //eslint-disable-line
                console.log(error);

                if (event == 'Save')
                {
                    bus.$emit('popupMessage', {
                        result: "error",
                        icon: "mdi-file-document-alert",
                        title: "Add User",
                        text: error.response.data.message
                    });

                    this.closeAddUser();
                    // this.resetPage();

                }
                else if(event == 'Update')
                {
                    bus.$emit('popupMessage', {
                        result: "error",
                        icon: "mdi-file-document-alert",
                        title: "Update User",
                        text: error.response.data.message
                    });

                    this.closeEditUser();
                    // this.resetPage();

                }

                // this.loadingTable = false;

                this.loadingBtnSubmit = false;

            });
        },

    },


};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';

thead.v-data-table-header > tr > th{
    font-size: 15px !important;
    color: black !important;
}

.cust-textFunction
{
    text-decoration: underline;
    color: grey;
    cursor: pointer;

}

.cust-textFunction:hover
{
    color: blue;
}

</style>


